<template>
  <div class="sidebar">
    <!-- sidebar logo -->
    <div class="sidebar__logo">
      <router-link to="/">
        <img src="/img/logo.svg" alt="AI Teading Logo">
      </router-link>
    </div>
    <!-- end sidebar logo -->

    <!-- sidebar nav -->
    <ul class="sidebar__nav">
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'all'}" @click="this.$parent.changeSearching('all')">
          <i class="fas fa-th-list"></i>
          <span>전체</span>
        </a>
      </li>
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'practice'}" @click="this.$parent.changeSearching('practice')">
          <i class="fas fa-book-reader"></i>
          <span>실습</span>
        </a>
      </li>
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'learning'}" @click="this.$parent.changeSearching('learning')">
          <i class="fas fa-chalkboard-teacher"></i>
          <span>학습</span>
        </a>
      </li>
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'picture'}" @click="this.$parent.changeSearching('picture')">
          <i class="fas fa-image"></i>
          <span>그림</span>
        </a>
      </li>
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'music'}" @click="this.$parent.changeSearching('music')">
          <i class="fas fa-music"></i>
          <span>음악</span>
        </a>
      </li>
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'recognition'}" @click="this.$parent.changeSearching('recognition')">
          <i class="fas fa-glasses"></i>
          <span>인식</span>
        </a>
      </li>
      <li class="sidebar__nav-item" style='margin-bottom: 9px;'>
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'tensorflow'}" @click="this.$parent.changeSearching('tensorflow')">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48" style='margin-left: -2px'>
            <polygon points="16,39.609 23,43.609 23,4 16,8"></polygon><polygon points="23,12.433 6,22.25 6,13.75 23,3.933"></polygon><polygon points="32,39.609 25,43.609 25,4 32,8"></polygon><polygon points="25,12.433 42,22.25 42,13.75 25,3.933"></polygon><polygon points="29,19.732 29,27.365 36,31.407 36,23.775"></polygon>
          </svg>
          <span>텐서플로우</span>
        </a>
      </li>
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'dqn'}" @click="this.$parent.changeSearching('dqn')">
          <b style='font-size: 75%; margin-right: 8px'>DQN</b>
          <span>DQN</span>
        </a>
      </li>
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'cnn'}" @click="this.$parent.changeSearching('cnn')">
          <b style='font-size: 75%; margin-right: 8px'>CNN</b>
          <span>CNN</span>
        </a>
      </li>
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'rnn'}" @click="this.$parent.changeSearching('rnn')">
          <b style='font-size: 75%; margin-right: 8px'>RNN</b>
          <span>RNN</span>
        </a>
      </li>
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'gan'}" @click="this.$parent.changeSearching('gan')">
          <b style='font-size: 75%; margin-right: 8px'>GAN</b>
          <span>GAN</span>
        </a>
      </li>
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'driving'}" @click="this.$parent.changeSearching('driving')">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <path d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z"/>
          </svg>
          <span>자율주행</span>
        </a>
      </li>
      <li class="sidebar__nav-item" style='margin-bottom: 9px;'>
        <a class='sidebar__nav-link' :class="{'sidebar__nav-link--active': sidebar === 'generative'}" @click="this.$parent.changeSearching('generative')">
          <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 66 66' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''><g><path d='M53.4 1c-6.1 0-11.1 5-11.1 11.1s5 11.1 11.1 11.1 11.1-5 11.1-11.1S59.5 1 53.4 1zm4.5 12-5.7 3.1c-.7.4-1.6-.1-1.6-.9V9c0-.8.9-1.3 1.6-.9l5.7 3.1c.7.4.7 1.4 0 1.8zM1.5 3.9V25c0 1.6 1.3 2.9 2.9 2.9h15.3c1.6 0 2.9-1.3 2.9-2.9V3.9c0-1.6-1.3-2.9-2.9-2.9H4.4C2.8 1 1.5 2.3 1.5 3.9zm5.7 1.9h9.6c.5 0 1 .4 1 1 0 .5-.4 1-1 1H7.2c-.5 0-1-.4-1-1 .1-.6.5-1 1-1zm0 5.1h9.6c.5 0 1 .4 1 1 0 .5-.4 1-1 1H7.2c-.5 0-1-.4-1-1 .1-.6.5-1 1-1zm0 5.1h9.6c.5 0 1 .4 1 1 0 .5-.4 1-1 1H7.2c-.5 0-1-.4-1-1 .1-.5.5-1 1-1zm0 5.2h9.6c.5 0 1 .4 1 1 0 .5-.4 1-1 1H7.2c-.5 0-1-.4-1-1 .1-.6.5-1 1-1zM27.1 14.4c2.7 0 5 2.2 5 5 0 .5.4 1 1 1 .5 0 1-.4 1-1 0-2.7 2.2-5 5-5 .5 0 1-.4 1-1 0-.5-.4-1-1-1-2.5 0-4.7 1.4-5.9 3.4-1.2-2-3.4-3.4-5.9-3.4-.5 0-1 .4-1 1-.2.6.3 1 .8 1zM56.9 30.6c-.3-.2-.7-.3-1-.1s-.5.5-.5.9v8.5c-.6-.3-1.2-.5-1.9-.5-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8 3.8-1.7 3.8-3.8V33.3l3.8 2.9c.4.3 1 .2 1.3-.2s.2-1-.2-1.3zM36.7 49.2V47c-2.5 1.3-4.9 1.3-7.4 0v2.2c0 2 1.7 3.7 3.7 3.7s3.7-1.7 3.7-3.7zM33 22.9c-5.2 0-9.4 4.6-9.4 10.2 0 .5 0 1 .1 1.6 3.7-.4 7.1-3 8.4-6.5.3-.8 1.3-.9 1.8-.3 2 2.5 5.1 4 8.4 3.9-.6-5-4.5-8.9-9.3-8.9z' data-original='#000000' class=''></path><path
            d='M24.1 36.7c1.2 4.2 4.6 9.1 8.9 9.1 4.8 0 9-6.4 9.4-11.9-3.3.1-6.6-1.1-9-3.5-1.9 3.5-5.3 5.8-9.3 6.3zM38.6 50.5c-.6 2.5-2.9 4.4-5.6 4.4s-5-1.9-5.6-4.4l-7.8 2.1c-3 1-5.2 3.4-5.5 6-.2 1.3-.1 4.7 0 6.4h37.7c.1-1.7.1-5 0-6.3-.3-2.6-2.5-5-5.5-6z'
            data-original='#000000' class=''></path></g></svg>
          <span>생성형</span>
        </a>
      </li>
      <li class="sidebar__nav-item">
        <a class="sidebar__nav-link" :class="{'sidebar__nav-link--active': sidebar === 'noCamera'}" @click="this.$parent.changeSearching('noCamera')">
          <i class="fas fa-eye-slash"></i>
          <span>웹캠 없음</span>
        </a>
      </li>
    </ul>
    <!-- end sidebar nav -->
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: ['sidebar', 'lang'],
  data() {
    return {
    }
  },
  methods: {

  }
};
</script>

<style>
</style>
