<template>
  <div class="container-fluid">
    <!-- slider -->
    <section class="row" :style="{ display: showBanner ? 'block' : 'none' }">

      <!-- title -->
      <!--<div class="col-12">
        <div class="main__title d-flex align-items-center" style='margin-bottom: 10px; margin-top: 20px'>
          <h3>관련 도서</h3>
          <a style='display: flex; cursor: pointer'><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" x="0" y="0" viewBox="0 0 329.269 329" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M194.8 164.77 323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0 0 15.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0" fill="#000000" opacity="1" data-original="#000000" style='fill: rgb(37, 165, 106)'></path></g></svg></a>
        </div>
      </div>-->
      <!-- end title -->

      <!--<div class="col-12">
        <Carousel :wrapAround='true' :transition='500' :settings="carouselSettings" :breakpoints="carouselBreakpoints" @slide-end="handleSlideEnd">
          <Slide v-for='(slide, index) in carouselModules' :key='index'>
            <div class='carousel__item'>
              <img :src="'img/thumbnail/' + slide.name + '.jpg'" :alt='slide.title' style='width: 100%'>
              <a :href="slide.href" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z"></path></svg></a>
            </div>
          </Slide>
        </Carousel>
        <div class="album__title mt-2">
          <h3><router-link :to="{ name: 'Article', params: { name: carouselCurrentInformation.name }}">{{carouselCurrentInformation.title}}</router-link></h3>
          <span><router-link :to="{ name: 'Article', params: { name: carouselCurrentInformation.name }}">{{carouselCurrentInformation.description}}</router-link></span>
        </div>
      </div>-->
    </section>
    <!-- end slider -->

    <!-- contents -->
    <section class="row row--grid" id="module-list">
      <!-- title -->
      <div class='col-12'>
        <div class='main__title d-flex align-items-center'>
          <h3 v-if="searching === ''">전체 자료</h3>
          <h3 v-else>"{{searching}}"&nbsp;<small>검색 결과</small>&nbsp;&nbsp;<a style='font-size: 50%; color: #25a56a; cursor: pointer' @click='clearSearching()'>검색 삭제</a></h3>
          <div>
            <form class='header__search'>
              <input type='text' id='search-input' placeholder='검색어를 입력하세요.' @input='keypressSearching($event)'>
              <button v-if="searching === ''" type='button' style='cursor: none'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                  <path d='M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z' />
                </svg>
              </button>
              <button v-else type='button' class='close d-block' @click='clearSearching()'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                  <path d='M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z' />
                </svg>
              </button>
            </form>
          </div>
        </div>
      </div>
      <!-- end title -->

      <!-- post -->
      <div class="col-12 col-sm-6 col-lg-3" v-for="item in searching !== ''? filteredModules : modules">
        <div class="post">
          <router-link :to="{ name: 'Article', params: { name: item.name }}" class="post__img">
            <img v-if="item.name !== ''" :src="'img/thumbnail/' + item.name + '.jpg'" :alt="item.title">
            <img v-else src="img/posts/1.jpg">
          </router-link>
          <a :href="item.href" class="post__video" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16,10.27,11,7.38A2,2,0,0,0,8,9.11v5.78a2,2,0,0,0,1,1.73,2,2,0,0,0,2,0l5-2.89a2,2,0,0,0,0-3.46ZM15,12l-5,2.89V9.11L15,12ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg> 바로 실행
          </a>
          <a class="post__another__link" target="_blank" v-if="item.anotherName" :href="item.anotherHref">{{item.anotherName}}</a>
          <div class="post__content">
            <a class="post__category" v-if="item.webcam" aria-label="웹캠이 필요합니다." data-balloon-pos="right"><img src="icon/webcam.svg"/></a>
            <router-link :to="{ name: 'Article', params: { name: item.name }}" class="post__title">
              <a :href="'/article/' + item.name + '.html'">{{item.title}}</a>
            </router-link>
            <p class="event__address">{{item.description}}</p>
            <div class="post__meta">
              <span class="post__date"><img src="icon/bookmark.svg"/> {{item.bookChapter}}</span>
              <span class="post__comments" v-if="item.bookPage"><img src="icon/page.svg"/> {{item.bookPage}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-4" v-if="searching !== '' && filteredModules.length === 0">
        <div class="main__title mt-3">
          <p>검색 결과가 없습니다.&nbsp;&nbsp;<a style='color: #25a56a; text-decoration: underline; cursor: pointer' @click='clearSearching()'>검색어 삭제</a></p>
        </div>
      </div>
    </section>
    <!-- end contents -->
  </div>

  <!-- modal lang -->
  <!--  <a href="#modal-lang" class="release__buy modal-button">language-modal</a>-->
  <form action="#" id="modal-lang" class="zoom-anim-dialog mfp-hide modal modal--form">
    <button class="modal__close" type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg></button>
    <h4 class="sign__title">言語の変更 · Change language</h4>
    <span class="sign__text sign__text--small">このサイトは日本語または英語に対応しています。<br/>This site is available in Japanese or English.</span>
    <button class="sign__btn" type="button" style='height: 40px' @click="changeLangFromPopup('ja')">日本語</button>
    <button class="sign__btn" type="button" style='height: 40px' @click="changeLangFromPopup('en')">English</button>
  </form>
  <!-- end modal lang -->
</template>

<script>
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import _, { map } from 'underscore'
export default {
  name: "Home",
  data() {
    return {
      carouselCurrentSlide: 0,
      carouselCurrentInformation: null,
      carouselModules: [],
      carouselSettings: {
        snapAlign: 'start',
        itemsToShow: 1.5
      },
      carouselBreakpoints: {
        768: {
          itemsToShow: 2.5
        },
        1200: {
          itemsToShow: 2.5
        },
      },
      searching: '',
      modules: store.state.modules,
      filteredModules: [],
      showBanner: false
    }
  },
  created() {
    this.carouselModules = _.sample(this.modules, 10);
    //this.carouselCurrentInformation = this.carouselModules[0];
    /*try {
      let showBanner = sessionStorage.getItem('showBanner');
      if(showBanner && showBanner === 'FALSE'){
        this.showBanner = false;
      }
    } catch (e) {
      console.error(e);
    }*/
  },
  mounted() {
    try {
      const storedSearching = sessionStorage.getItem('searching');
      if(storedSearching && storedSearching !== ''){
        this.changeSearchingFromParent(storedSearching);
      }
    } catch (e) {
      console.error(e);
    }

    try {
      if(sessionStorage.getItem('checkedLang')){
        return;
      }

      let lang;
      if(navigator.language != null) lang = navigator.language;
      lang = lang.toLowerCase().substring(0,2);

      if(lang !== 'ko'){
        this.showLangPopup();
        sessionStorage.setItem('checkedLang', 'true');
      }
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    handleSlideEnd(data) {
      //this.carouselCurrentSlide = data.currentSlideIndex;
      //this.carouselCurrentInformation = this.carouselModules[this.carouselCurrentSlide];
    },
    keypressSearching(event){
      this.searching = event.target.value;
      this.filterSearching();
    },
    changeSearchingFromParent(text){
      this.searching = text;
      $('#search-input').val(text);
      this.filterSearching();
    },
    filterSearching(){
      let filteredByTitle = this.modules.filter(p => p.title.toLowerCase().includes(this.searching.toLowerCase()));
      let filteredByDescription = this.modules.filter(p => p.description.toLowerCase().includes(this.searching.toLowerCase()));
      let filteredByBookChapter = this.modules.filter(p => p.bookChapter.toLowerCase().includes(this.searching.toLowerCase()));
      let filteredByPage = this.modules.filter(p => p.bookPage.includes(this.searching));
      let filteredByWebcam = this.modules.filter(p => (p.webcam === true && this.searching === '웹캠 있음') || (p.webcam === false && this.searching === '웹캠 없음'));

      const merged = [...filteredByTitle, ...filteredByDescription, ...filteredByBookChapter, ...filteredByPage, ...filteredByWebcam];
      const destArray = _.uniq(merged, x => x.name);
      this.filteredModules = destArray;

      sessionStorage.setItem('searching', this.searching);
      this.$emit('change-sidebar', this.searching);
    },
    clearSearching(){
      this.searching = '';
      $('#search-input').val('');
      sessionStorage.removeItem('searching');
      this.$emit('change-sidebar', this.searching);
    },
    toggleShowBanner(url){
      this.showBanner = false;
      sessionStorage.setItem('showBanner', 'FALSE');

      if(url){
        window.open(url, '_blank');
      }
    },
    showLangPopup(){
      $.magnificPopup.open({
        items: {
          src: '#modal-lang',
        },
        type: 'inline'
      });
    },
    changeLangFromPopup(lang){
      this.$emit('change-language', lang);
      $.magnificPopup.close();
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  }
}
</script>

<style scoped>
.carousel__slide {
  padding: 5px;
}
.carousel__viewport {
  perspective: 2000px;
}
.carousel__track {
  transform-style: preserve-3d;
}
.carousel__slide--sliding {
  transition: 0.5s;
}
.carousel__slide {
  opacity: 0.75;
  transform: rotateY(-20deg) scale(0.9);
}
.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}
.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}
.carousel__slide--next {
  opacity: 0.75;
  transform: rotateY(10deg) scale(0.95);
}
.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}

.carousel__item a {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  top: 50%;
  left: 50%;
  margin: -28px 0 0 -28px;
  border-radius: 12px;
  background-color: #222227;
  opacity: 0;
  pointer-events: none;
  transform: scale(1);
  transition: 0.5s ease;
  border: 1px solid rgba(255,255,255,0.05);
}
.carousel__item a svg {
  fill: #fff;
  width: 24px;
  height: auto;
  margin: 1px 0 0 1px;
  transition: fill 0.5s ease;
}
.carousel__item a:hover svg {
  fill: #25a56a;
}
.carousel__slide--active .carousel__item a{
  opacity: 1;
  pointer-events: auto;
}
</style>
