<template>
  <div id="app">
    <!-- header -->
    <JHeader v-if="lang === 'ja'"></JHeader>
    <EHeader v-else-if="lang === 'en'"></EHeader>
    <Header v-else></Header>
    <!-- end header -->

    <!-- sidebar -->
    <JSidebar v-if="lang === 'ja'"></JSidebar>
    <ESidebar v-else-if="lang === 'en'"></ESidebar>
    <Sidebar v-else></Sidebar>
    <!-- end sidebar -->

    <main class="main">
      <router-view @change-sidebar="changeSideBar" @change-language="changeLanguage" v-slot="{Component}">
        <component ref="view" :is="Component" />
      </router-view>
    </main>

    <!-- footer -->
    <JFooter v-if="lang === 'ja'"></JFooter>
    <EFooter v-else-if="lang === 'en'"></EFooter>
    <Footer v-else></Footer>
    <!-- end footer -->
  </div>
</template>

<script>
import Header from "./views/components/Header.vue";
import Footer from "./views/components/Footer.vue";
import Sidebar from "./views/components/Sidebar.vue";
import JHeader from "./views/ja/JA_Header.vue";
import JFooter from "./views/ja/JA_Footer.vue";
import JSidebar from "./views/ja/JA_Sidebar.vue";
import EHeader from "./views/en/EN_Header.vue";
import EFooter from "./views/en/EN_Footer.vue";
import ESidebar from "./views/en/EN_Sidebar.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    Sidebar,
    JHeader,
    JFooter,
    JSidebar,
    EHeader,
    EFooter,
    ESidebar,
  },
  data() {
    return {
      sidebar: 'all',
      lang: store.state.lang,
    }
  },
  watch: {
    $route() {
      if(this.$route.name.indexOf('JA') !== -1){
        store.state.lang = 'ja';
      }else if(this.$route.name.indexOf('EN') !== -1){
        store.state.lang = 'en';
      }else{
        store.state.lang = 'ko';
      }
      this.lang = store.state.lang;
    }
  },
  methods: {
    changeSideBar(name) {
      this.sidebar = store.state.sidebarNameToCode[this.lang][name];
    },
    changeSearching(sidebar){
      this.sidebar = sidebar;
      if(this.$route.name === 'Home' || this.$route.name === 'JA_Home' || this.$route.name === 'EN_Home'){
        this.$refs.view.changeSearchingFromParent(store.state.sidebarCodeToName[this.lang][sidebar]);
      }else{
        if(this.lang === 'ja'){
          this.$router.push('/ja');
        }else if(this.lang === 'en'){
          this.$router.push('/en');
        }else{
          this.$router.push('/');
        }
        setTimeout(() => {
          this.$refs.view.changeSearchingFromParent(store.state.sidebarCodeToName[this.lang][sidebar]);
        }, 500);
      }

      setTimeout(() => {
        $('html, body').animate({
          scrollTop: $("#module-list").length === 0 ? 0 : $("#module-list").offset().top
        }, 250);
      }, 250);
    },
    changeLanguage(lang){
      if(store.state.lang === lang){
        return;
      }

      store.state.lang = lang;
      this.lang = store.state.lang;
      switch (lang){
        case 'ko': this.$router.push('/'); break;
        case 'ja': this.$router.push('/ja'); break;
        case 'en': this.$router.push('/en'); break;
        default: this.$router.push('/'); store.state.lang = 'ko';
      }

    },
    toggleSidebar(){
      $('.header__btn').toggleClass('header__btn--active');
      $('.sidebar').toggleClass('sidebar--active');
    }
  }
}
</script>

<style>
</style>
