import { createRouter, createWebHistory } from 'vue-router/dist/vue-router.esm-bundler'
import Home from './views/Home.vue'

export default () => createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/article/:name',
      name: 'Article',
      component: () => import('./views/Article.vue')
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('./views/About.vue')
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('./views/Privacy.vue')
    },
    {
      path: '/license',
      name: 'License',
      component: () => import('./views/License.vue')
    },
    {
      path: '/ja',
      name: 'JA_Home',
      component: () => import('./views/ja/JA_Home.vue')
    },
    {
      path: '/ja/article/:name',
      name: 'JA_Article',
      component: () => import('./views/ja/JA_Article.vue')
    },
    {
      path: '/ja/about',
      name: 'JA_About',
      component: () => import('./views/ja/JA_About.vue')
    },
    {
      path: '/ja/license',
      name: 'JA_License',
      component: () => import('./views/ja/JA_License.vue')
    },
    {
      path: '/ja/privacy',
      name: 'JA_Privacy',
      component: () => import('./views/ja/JA_Privacy.vue')
    },
    {
      path: '/en',
      name: 'EN_Home',
      component: () => import('./views/en/EN_Home.vue'),
    },
    {
      path: '/en/article/:name',
      name: 'EN_Article',
      component: () => import('./views/en/EN_Article.vue')
    },
    {
      path: '/en/about',
      name: 'EN_About',
      component: () => import('./views/en/EN_About.vue')
    },
    {
      path: '/en/license',
      name: 'EN_License',
      component: () => import('./views/en/EN_License.vue')
    },
    {
      path: '/en/privacy',
      name: 'EN_Privacy',
      component: () => import('./views/en/EN_Privacy.vue')
    },
  ]
})
