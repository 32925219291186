<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4 order-4 order-md-1 order-lg-4 order-xl-1">
          <div class="footer__logo">
            <router-link to="/en">
              <img src="/img/logo.svg" alt="AI Teading Logo">
            </router-link>
          </div>
          <p class="footer__tagline">This is a site that makes it easy to experience A.I.<br/>Check out the various features of A.I.</p>
          <div class="footer__links">
            <a href="mailto:codingteading@gmail.com"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19,4H5A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm-.41,2-5.88,5.88a1,1,0,0,1-1.42,0L5.41,6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7.41l5.88,5.88a3,3,0,0,0,4.24,0L20,7.41Z"/></svg> codingteading@gmail.com</a>
          </div>
        </div>

        <div class="col-6 col-md-4 col-lg-3 col-xl-2 order-1 order-md-2 order-lg-1 order-xl-2 offset-md-2 offset-lg-0">
          <h6 class="footer__title">Help</h6>
          <div class="footer__nav">
            <router-link to="/en/about">About</router-link>
            <router-link to="/en/license">License</router-link>
            <router-link to="/en/privacy">Privacy</router-link>
          </div>
        </div>

        <div class="col-6 col-md-4 col-lg-3 col-xl-2 order-1 order-md-2 order-lg-1 order-xl-2 offset-md-2 offset-lg-0">
          <h6 class="footer__title">Category</h6>
          <div class="footer__nav">
            <a class="footer_anchor" @click="this.$parent.changeSearching('all')">All</a>
            <a class="footer_anchor" @click="this.$parent.changeSearching('practice')">Practice</a>
            <a class="footer_anchor" @click="this.$parent.changeSearching('learning')">Learning</a>
            <a class="footer_anchor" @click="this.$parent.changeSearching('picture')">Image</a>
            <a class="footer_anchor" @click="this.$parent.changeSearching('music')">Music</a>
          </div>
        </div>

        <div class="col-6 col-md-4 col-lg-3 col-xl-2 order-1 order-md-2 order-lg-1 order-xl-2 offset-md-2 offset-lg-0">
          <h6 class="footer__title" style='visibility: hidden'>BLANK</h6>
          <div class="footer__nav">
            <a class="footer_anchor" @click="this.$parent.changeSearching('recognition')">Recognition</a>
            <a class="footer_anchor" @click="this.$parent.changeSearching('tensorflow')">TensorFlow</a>
            <a class="footer_anchor" @click="this.$parent.changeSearching('dqn')">DQN</a>
            <a class="footer_anchor" @click="this.$parent.changeSearching('cnn')">CNN</a>
            <a class="footer_anchor" @click="this.$parent.changeSearching('rnn')">RNN</a>
          </div>
        </div>

        <div class="col-6 col-md-4 col-lg-3 col-xl-2 order-2 order-lg-3 order-md-4 order-xl-4">
          <h6 class="footer__title" style='visibility: hidden'>BLANK</h6>
          <div class="footer__nav">
            <a class="footer_anchor" @click="this.$parent.changeSearching('gan')">GAN</a>
            <a class="footer_anchor" @click="this.$parent.changeSearching('driving')">Autonomous</a>
            <a class="footer_anchor" @click="this.$parent.changeSearching('generative')">Generative</a>
            <a class="footer_anchor" @click="this.$parent.changeSearching('noCamera')">No Camera</a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="footer__content">
            <div class="footer__social">
              <a href="mailto:codingteading@gmail.com" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g data-name="Layer 2"><g data-name="01.mail"><circle cx="256" cy="256" r="256" fill="#2196f3" data-original="#2196f3" class=""></circle><g fill="#fff"><path d="M255.94 268.64 121 201.32v133.76a25.66 25.66 0 0 0 25.59 25.59h218.82A25.66 25.66 0 0 0 391 335.08V202.73z" fill="#ffffff" data-original="#ffffff" class=""></path><path d="M256.06 243.36 391 177.5v-.58a25.66 25.66 0 0 0-25.59-25.59H146.59A25.66 25.66 0 0 0 121 176z" fill="#ffffff" data-original="#ffffff" class=""></path></g></g></g></g></svg>
              </a>
              <a href="https://codingteading.com/" target="_blank">
                <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m256.251.5c141.383 0 256 114.616 256 256s-114.616 256-256 256-256-114.622-256-256 114.616-256 256-256zm-110.577 247.842v150.715h63.5v-90.6a6.968 6.968 0 0 1 6.967-6.963h80.215a6.964 6.964 0 0 1 6.962 6.963v90.6h63.512v-150.715l-110.582-80.337zm-29.4 4.154 135.769-98.64a6.974 6.974 0 0 1 8.29-.089l135.887 98.733 17.53-24.123-157.5-114.429-157.499 114.425z" fill="#4caf50" fill-rule="evenodd"/></svg>
              </a>
              <a href="https://www.youtube.com/channel/UCDZR1BZbnn66zmkx11KqfLQ" target="_blank"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z" fill="#FF0000"/><path d="M22.6656 11.2958C22.4816 10.5889 21.9395 10.0322 21.251 9.84333C20.0034 9.5 15 9.5 15 9.5C15 9.5 9.99664 9.5 8.74891 9.84333C8.06045 10.0322 7.51827 10.5889 7.33427 11.2958C7 12.5769 7 15.25 7 15.25C7 15.25 7 17.923 7.33427 19.2042C7.51827 19.9111 8.06045 20.4678 8.74891 20.6568C9.99664 21 15 21 15 21C15 21 20.0034 21 21.251 20.6568C21.9395 20.4678 22.4816 19.9111 22.6656 19.2042C23 17.923 23 15.25 23 15.25C23 15.25 23 12.5769 22.6656 11.2958" fill="white"/><path d="M13.5 18V13L17.5 15.5001L13.5 18Z" fill="#FF0000"/></svg></a>
            </div>
            <small class="footer__copyright">© 2023-2024 CodingTeading All rights reserved.</small>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "EFooter",
  data() {
    return {
    }
  },
  methods: {

  }
};
</script>

<style>
</style>
