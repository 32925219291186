<template>
  <header class="header">
    <div class="header__content">
      <div class="header__logo">
        <router-link to="/">
          <img src="/img/logo.svg" alt="AI Teading Logo">
        </router-link>
      </div>

      <div class="header__actions">
        <div class="header__action header__action--note" style='width: auto'>
          <span>한</span>
          <a class="header__action-btn"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="m195.708 268.059-30-150.001A14.999 14.999 0 0 0 151 106h-30a14.999 14.999 0 0 0-14.708 12.058l-30 150c-1.625 8.124 3.644 16.026 11.767 17.65 8.123 1.625 16.026-3.644 17.65-11.767L115.297 226h41.406l9.588 47.942c1.625 8.127 9.531 13.392 17.65 11.767 8.123-1.625 13.392-9.527 11.767-17.65zM121.297 196l12-60h5.406l12 60h-29.406zM436 226h-45v-15c0-8.284-6.716-15-15-15s-15 6.716-15 15v15h-45c-8.284 0-15 6.716-15 15s6.716 15 15 15h3.63c8.547 27.612 21.415 48.806 35.575 65.79-11.525 10.542-23.187 19.187-33.575 27.497-6.469 5.175-7.518 14.614-2.342 21.083 5.178 6.472 14.618 7.515 21.083 2.342 10.446-8.357 22.967-17.644 35.629-29.264 12.671 11.628 25.215 20.932 35.629 29.264 6.469 5.176 15.909 4.126 21.083-2.342 5.175-6.469 4.126-15.909-2.342-21.083-10.361-8.291-22.038-16.945-33.575-27.497 14.16-16.984 27.028-38.178 35.575-65.79H436c8.284 0 15-6.716 15-15s-6.716-15-15-15zm-60 73.745c-9.575-12.02-18.189-26.367-24.683-43.845h49.365c-6.493 17.478-15.107 31.825-24.682 43.845z" fill="#FFFFFF" opacity="1" data-original="#FFFFFF" class=""></path><path d="M467 91H250.599l-6.43-51.582C241.36 16.946 222.164 0 199.517 0H45C20.187 0 0 20.187 0 45v331c0 24.813 20.187 45 45 45h126.483l6.348 51.582c2.804 22.427 22 39.418 44.653 39.418H467c24.813 0 45-20.187 45-45V136c0-24.813-20.187-45-45-45zM45 391c-8.271 0-15-6.729-15-15V45c0-8.271 6.729-15 15-15h154.517c7.549 0 13.948 5.648 14.883 13.134L257.764 391H45zm161.724 70.75L201.709 421h40.244l-35.229 40.75zM482 467c0 8.271-6.729 15-15 15H228.874l57.104-66.053a14.976 14.976 0 0 0 3.629-12.024L254.339 121H467c8.271 0 15 6.729 15 15v331z" fill="#FFFFFF" opacity="1" data-original="#FFFFFF" class=""></path></g></svg></a>
          <div class="header__drop">
            <div class="header__note header__note--succ cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>
              <p><a class="open-modal">한국어</a></p>
            </div>
            <div class="header__note header__note--succ cursor-pointer" @click="this.$parent.changeLanguage('ja')">
              <p style='margin-left: 21px'><a class="open-modal">日本語</a></p>
            </div>
            <div class="header__note header__note--succ cursor-pointer" @click="this.$parent.changeLanguage('en')">
              <p style='margin-left: 21px'><a class="open-modal">ENGLISH</a></p>
            </div>
          </div>
        </div>
        <div class="header__action header__action--signin">
          <a class="header__action-btn d-none d-md-block" style='height: 100%' href="https://codingteading.com/">
            <span>코딩티딩 홈</span>
          </a>
        </div>
      </div>

      <button class="header__btn" type="button" @click='this.$parent.toggleSidebar()'>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
    }
  },
  methods: {

  }
};
</script>

<style>
</style>
